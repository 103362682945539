import { SELECTORS } from '../../lib/selectors'
import { toggleScrollbar } from '../../lib/toggleScrollbar'
import barba from '@barba/core';

/* eslint-disable no-console */

export const initHeader = () => {
  const header = document.querySelector('[data-header="header"]')
  const headerTrigger = document.querySelector('[data-header="trigger"]')

  if (!header) {
    console.warn('Header required [data-header="header"]')
    return
  }

  if (!headerTrigger) {
    console.warn('Header trigger required [data-header="trigger"]')
    return
  }

  const options = {
    rootMargin: '0px',
  }

  const callback = entries => {
    entries.forEach(entry => {
      header.classList.toggle(SELECTORS.active, !entry.isIntersecting)
    })
  }

  const observer = new IntersectionObserver(callback, options)

  observer.observe(headerTrigger)
}

export const initMobileMenu = () => {
  const menuTriggers = document.querySelectorAll('[data-menu="trigger"]')
  const menuContent = document.querySelector('[data-menu="content"]')
  const menuLinks = document.querySelectorAll('[data-menu="links"] a')

  if (!menuLinks.length) {
    console.warn('At least one menu link required [data-menu="links"] a')
  }

  if (!menuTriggers.length) {
    console.warn('At least one menu trigger required [data-menu="trigger"]')
    return
  }

  if (!menuContent) {
    console.warn('Menu content required [data-menu="content"]')
    return
  }

  let timeout = null

  const toggleMenuState = opened => {
    document.documentElement.classList.toggle(SELECTORS.menu_opened, opened)
    clearTimeout(timeout)

    timeout = setTimeout(
      () => {
        toggleScrollbar(opened)
        document.documentElement.classList.toggle(SELECTORS.has_modal, opened)
      },
      opened ? 0 : 750
    )
  }

  const isMenuOpened = () =>
    document.documentElement.classList.contains(SELECTORS.menu_opened)

  menuTriggers.forEach(trigger =>
    trigger.addEventListener('click', () => {
      toggleMenuState(!isMenuOpened())
    })
  )

  barba.hooks.beforeLeave(() => {
    toggleMenuState(false)
    menuLinks.forEach((link) => {
      link.classList.toggle(
        SELECTORS.active,
        link.href.includes(window.location.pathname)
      )
    })
  })
}

export const initSearch = () => {
  const searchTriggers = document.querySelectorAll('[data-search="trigger"]')
  const searchClose = document.querySelectorAll('[data-search="close"]')
  const searchContent = document.querySelector('[data-search="content"]')

  if (!searchTriggers.length) {
    // eslint-disable-next-line no-console
    console.warn('At least one search trigger required [data-search="trigger"]')
    return
  }

  if (!searchContent) {
    // eslint-disable-next-line no-console
    console.warn('Menu content required [data-search="content"]')
    return
  }

  const toggleSearchState = opened => {
    searchContent.classList.toggle(SELECTORS.active, opened)
  }

  const isSearchOpened = () =>
    searchContent.classList.contains(SELECTORS.active)

  searchTriggers.forEach(trigger =>
    trigger.addEventListener('click', (e) => {
      e.preventDefault()
      toggleSearchState(!isSearchOpened())
    })
  )

  searchClose.forEach(trigger =>
    trigger.addEventListener('click', (e) => {
      e.preventDefault()
      toggleSearchState(false)
    })
  )
}
