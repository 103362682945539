/* eslint-disable no-console */

import { SELECTORS } from '../../lib/selectors'
import './tabs.scss'

export const initTabs = container => {
  const tabIndexAttr = 'data-tab-index'
  const tabItems = Array.from(container.querySelectorAll('[data-tab="item"]'))
  const tabTriggers = Array.from(
    container.querySelectorAll('[data-tab="trigger"]')
  )

  if (!tabItems.length) {
    console.warn('Tab items required to init tabs: [data-tab="item"]')

    return
  }

  if (!tabTriggers.length) {
    console.warn('Tab triggers required to init tabs: [data-tab="trigger"]')

    return
  }

  tabTriggers.forEach(trigger => {
    trigger.addEventListener('click', e => {
      e.preventDefault()

      tabTriggers.forEach(tabTrigger => {
        if (!tabTrigger.hasAttribute(tabIndexAttr)) {
          console.warn(
            `Trigger doesn't have required attribute [${tabIndexAttr}]`,
            tabTrigger
          )

          return
        }

        tabTrigger.classList.toggle(
          SELECTORS.active,
          tabTrigger.dataset.tabIndex === trigger.dataset.tabIndex
        )
      })

      tabItems.forEach(tabItem => {
        if (!tabItem.hasAttribute(tabIndexAttr)) {
          console.warn(
            `Tab item doesn't have required attribute [${tabIndexAttr}]`,
            tabItem
          )

          return
        }

        tabItem.classList.toggle(
          SELECTORS.active,
          tabItem.dataset.tabIndex === trigger.dataset.tabIndex
        )
      })

      trigger.classList.add(SELECTORS.active)
    })
  })

  const activeTabTrigger = tabTriggers.find(el =>
    el.classList.contains(SELECTORS.active)
  )

  if (activeTabTrigger) {
    const activeTabItem = tabItems.find(
      el => el.dataset.tabIndex === activeTabTrigger.dataset.tabIndex
    )

    if (!activeTabItem) {
      console.warn(
        `Can't init active tab. Tab item with data-index not found`,
        `[${tabIndexAttr}=${activeTabTrigger.dataset.tabIndex}]`,
        activeTabTrigger
      )

      return
    }

    activeTabItem.classList.add(SELECTORS.active)
  }
}
