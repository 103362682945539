// node-modules
import Swiper from 'swiper'

// assets
import 'swiper/swiper-bundle.min.css'
import './swiperSlider.scss'
import './specialOffersSlider/specialOffersSlider.scss'
import './testimonialsSlider/testimonialsSlider.scss'
import './scheduleSlider/scheduleSlider.scss'
import './singleImageSlider/singleImageSlider.scss'

const sliderOptions = {
  speed: 1000,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2,
  },
  keyboard: true,
  observer: true,
  observeParents: true,
}

export function testimonialsSlider(sliderContainer) {
  const swiper = new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      effect: 'slide',
      spaceBetween: 32,
      slidesPerView: 1,
      slideToClickedSlide: true,
      initialSlide: 0,
      pagination: {
        el: '[data-role="slider-pagination"]',
        type: 'bullets',
      },
      navigation: {
        nextEl: sliderContainer.querySelector('[data-slider-next]'),
        prevEl: sliderContainer.querySelector('[data-slider-prev]'),
      },
    }
  )
}

export function specialOffersSlider(sliderContainer) {
  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      effect: 'slide',
      spaceBetween: 152,
      slidesPerView: 1,
      slideToClickedSlide: true,
      initialSlide: 0,
      breakpoints: {
        0: {
          spaceBetween: 32,
        },
        1024: {
          spaceBetween: 152,
        },
      },
    }
  )
}

export function classesSlider(sliderContainer) {
  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      effect: 'slide',
      spaceBetween: 32,
      slideToClickedSlide: true,
      initialSlide: 0,
      breakpoints: {
        0: {
          slidesPerView: 1.3,
          spaceBetween: 16,
        },
        640: {
          slidesPerView: 2.2,
          spaceBetween: 16,
        },
        769: {
          slidesPerView: 3,
          spaceBetween: 32,
        },
        1024: {
          slidesPerView: 2.5,
        },
        1200: {
          slidesPerView: 3,
        },
      },
    }
  )
}

export function scheduleSlider(sliderContainer) {
  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      effect: 'slide',
      spaceBetween: 16,
      initialSlide: 0,
      freeMode: true,
      slidesPerView: 'auto',
    }
  )
}

export function singleImageSlider(sliderContainer) {
  return new Swiper(
    sliderContainer.querySelector('[data-role="slider-container"]'),
    {
      ...sliderOptions,
      effect: 'fade',
      autoplay: {
        delay: 3000,
      },
      fadeEffect: {
        crossFade: true,
      },
      spaceBetween: 0,
      initialSlide: 0,
      slidesPerView: 1,
      pagination: {
        el: '[data-role="slider-pagination"]',
        type: 'bullets',
        clickable: 'true',
      },
    }
  )
}
