import './ctaBlock.scss'
import { SELECTORS } from '../../lib/selectors'

/* eslint-disable no-console */

export function ctaBlock() {
  const cta = document.querySelector('[data-cta]')
  const ctaTrigger = document.querySelector('[data-cta-trigger]')

  if (cta) {
    const button = cta.querySelector('[data-cta-button]')
    button.addEventListener('click', event => {
      event.preventDefault()
      cta.classList.toggle('is-open')
    })
  }

  if (!cta) {
    console.warn('Cta required [data-cta]')
    return
  }

  if (!ctaTrigger) {
    console.warn('Cta trigger required [data-cta-trigger]')
    return
  }

  const options = {
    rootMargin: '0px',
  }

  const callback = entries => {
    entries.forEach(entry => {
      cta.classList.toggle(SELECTORS.active, !entry.isIntersecting)
    })
  }

  const ctaObserver = new IntersectionObserver(callback, options)

  ctaObserver.observe(ctaTrigger)
}
