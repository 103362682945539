/**
 * Entry point for webpack
 * Import styles, fonts, icons, scripts and other things that your project design requires here
 */

import objectFitImages from 'object-fit-images'
import svg4everybody from 'svg4everybody'
import barba from '@barba/core'

import './styles/main.scss'
import './styles/_form.scss'
import './components/coverForm/coverForm.scss'
import './components/header/header.scss'
import './components/footer/footer.scss'
import './components/partners/partners.scss'
import './components/classes/classes.scss'
import './components/gifts/gifts.scss'
import './components/share/share.scss'

import Swiper, { Pagination, EffectFade, Autoplay, Navigation } from 'swiper'
import { initTabs } from './components/tabs'
import { scrollToTop } from './components/scrollToTop/scrollToTop'
import {
  initMobileMenu,
  initHeader,
  initSearch,
} from './components/header/header'
import { lazyLoading } from './lazyLoading'
import { staticFooter } from './staticFooter'
import {
  specialOffersSlider,
  testimonialsSlider,
  classesSlider,
  scheduleSlider,
  singleImageSlider,
} from './components/swiperSlider/swiperSlider'
import { initModal } from './components/modal'
import { ctaBlock } from './components/ctaBlock/ctaBlock'
import form from './form'
import { scrollAnimations } from './animations/scroll-animations'
import { initLetters } from './letters/letters.scripts'
import { initPreloader } from './components/preloader/preloader'
import { initGoogleMaps } from './components/googleMaps'
import { initSchedule } from './components/schedule/schedule'
import { initAccountLinks } from './components/accountLinks/accountLinks'
import { customInputNumber } from './components/customInputNumber'
import {
  initBeneficiaryAddressForm,
  initBeneficiaryForm,
} from './components/beneficiaryForm/beneficiaryForm'

Swiper.use([Pagination, EffectFade, Autoplay, Navigation])

const oneTimeThings = () => {
  // svg sprites support in IE11
  svg4everybody()
  // scroll to top button
  scrollToTop()
  // header
  initHeader()
  // mobile menu
  initMobileMenu()
  // search
  initSearch()
  // static footer
  staticFooter()
}

// initialize things inside of data-barba="container"
const onPageLoad = page => {
  // object-fit support in IE11
  objectFitImages()
  // images lazy loading
  lazyLoading()
  // init modal
  initModal(page)
  // init form
  form(page)
  // init letters
  initLetters(page)
  // init schedule
  initSchedule(page)

  page.querySelectorAll('[data-modal-form-trigger]').forEach(el => {
    el.addEventListener('click', () => {
      const modal = document.querySelector('.modal')

      if (!modal) {
        return
      }

      form(modal)
    })
  })

  initAccountLinks(page)
  customInputNumber()

  // anchor links
  page.querySelectorAll('[href*="#"]').forEach(link => {
    link.addEventListener('click', e => {
      const selector = link.href.split('#').pop()

      if (!selector) {
        return
      }

      const target = document.getElementById(selector)

      if (!target) {
        return
      }

      e.preventDefault()

      target.scrollIntoView({ behavior: 'smooth' })
    })
  })

  // beneficiary form
  initBeneficiaryForm(page)
  initBeneficiaryAddressForm(page)

  // sliders
  document
    .querySelectorAll('[data-role="testimonials-slider"]')
    .forEach(container => testimonialsSlider(container))

  document
    .querySelectorAll('[data-role="special-offers-slider"]')
    .forEach(container => specialOffersSlider(container))

  document
    .querySelectorAll('[data-role="classes-slider"]')
    .forEach(container => classesSlider(container))

  document
    .querySelectorAll('[data-role="schedule-slider"]')
    .forEach(container => scheduleSlider(container))

  document
    .querySelectorAll('[data-role="single-image-slider"]')
    .forEach(container => singleImageSlider(container))

  // cta
  ctaBlock()
  // tabs
  document
    .querySelectorAll('[data-tabs="container"]')
    .forEach(container => initTabs(container))

  // init google maps
  document
    .querySelectorAll('[data-role="google-maps-container"]')
    .forEach(el => initGoogleMaps(el))

  page.querySelectorAll('[data-reset-button]').forEach(button => {
    button.addEventListener('click', () => {
      Array.from(button.form.elements)
        .filter(el => el.tagName !== 'BUTTON')
        .forEach(el => {
          el.value = ''
          el.dispatchEvent(new Event('input'))
        })
    })
  })
}

// things that require preloader to be removed
const afterPreloader = page => {
  scrollAnimations(page)
}

document.addEventListener('DOMContentLoaded', () => {
  oneTimeThings()

  const container = document.querySelector('[data-barba="container"]')
  onPageLoad(container)
  initPreloader().then(() => afterPreloader(document))

  if ('withBarba' in document.body.dataset) {
    barba.init({
      transitions: [
        {
          enter({ next }) {
            onPageLoad(next.container)
            afterPreloader(next.container)
          },
        },
      ],
    })

    barba.hooks.afterEnter(() => {
      window.scrollTo(0, 0)
    })
  }
})
