import { SELECTORS } from '../../lib/selectors'

export const initAccountLinks = page => {
  page.querySelectorAll('[data-account-link]').forEach(link => {
    if (link.classList.contains(SELECTORS.active)) {
      // https://developer.mozilla.org/ru/docs/Web/API/Element/scrollIntoView
      link.scrollIntoView(false)
    }
  })
}
