import './schedule.scss'

export const initSchedule = (page) => {
  const scheduleForm = page.querySelector('[data-schedule-form]');
  const scheduleInput = page.querySelector('[data-schedule-input]')

  if (scheduleInput && scheduleForm) {
    scheduleInput.addEventListener('change', () => {
      scheduleForm.submit()
    })
  }
}
