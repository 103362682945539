/* eslint-disable no-console */

import 'nodelist-foreach-polyfill'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import './modal.scss'
import { toggleScrollbar } from '../../lib/toggleScrollbar'

let modal
let closeButton
let modalContent
let isSetup

const closeIconHtml = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18 6L6 18" stroke="#1A1919" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6 6L18 18" stroke="#1A1919" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`

const readyClass = 'modal-ready'

function setup() {
  modal = document.createElement('div')
  modal.classList.add('modal')
  modal.setAttribute('role', 'dialog')
  modal.setAttribute('aria-modal', 'true')
  document.body.appendChild(modal)
  modalContent = document.createElement('div')
  modalContent.classList.add('modal__content')
  modal.appendChild(modalContent)
  closeButton = document.createElement('button')
  closeButton.innerHTML = closeIconHtml
  closeButton.classList.add('modal__close')
  modalContent.appendChild(closeButton)
  isSetup = true
}

function setHtml(html) {
  modal.appendChild(closeButton)
  modalContent.innerHTML = html
  modalContent.appendChild(closeButton)
}

function closeModal() {
  toggleScrollbar(false)
  modal.classList.remove('modal_opened')
  enableBodyScroll(modalContent)
  setTimeout(() => {
    setHtml('')
  }, 300)
}

function openModal(html) {
  toggleScrollbar(true)
  setHtml(html)
  modal.classList.add('modal_opened')
  disableBodyScroll(modalContent)
  document.body.style.marginRight = ''
}

export const initModal = container => {
  if (!isSetup) {
    setup()
    closeButton.addEventListener('click', closeModal)
  }

  container.querySelectorAll('[data-modal-wrapper]').forEach(wrapper => {
    const toggle = wrapper.querySelector('[data-modal-toggle]')
    const content = wrapper.querySelector('[data-modal-content]')
    const html = content.innerHTML

    toggle.addEventListener('click', () => {
      openModal(html)
    })

    toggle.addEventListener('keydown', e => {
      if (e.keyCode === 13 || e.keyCode === 32) {
        openModal(html)
      }
    })
  })

  window.addEventListener('keydown', e => {
    if (e.key === 'Escape') {
      closeModal()
    }
  })
}
