import { SELECTORS } from '../../lib/selectors'

export class BeneficiaryAddressForm {
  constructor(form, checkbox) {
    this.form = form
    this.checkbox = checkbox
    this.existingAddressOption = form.querySelector(
      '[data-existing-address-option]'
    )
    this.existingAddressInputs = form.querySelectorAll(
      '[data-existing-address] [required]'
    )
    this.newAddressOption = form.querySelector('[data-new-address-option]')
    this.newAddressInputs = form.querySelectorAll(
      '[data-new-address] [required]'
    )

    this.init()
  }

  render() {
    this.form.classList.toggle(SELECTORS.active, this.checkbox.checked)

    this.existingAddressInputs.forEach(input => {
      if (this.checkbox.checked && this.existingAddressOption.checked) {
        input.setAttribute('required', '')
      } else {
        input.removeAttribute('required')
      }

      const event = new Event('change')

      input.dispatchEvent(event)
    })

    this.newAddressInputs.forEach(input => {
      if (this.checkbox.checked && this.newAddressOption.checked) {
        input.setAttribute('required', '')
      } else {
        input.removeAttribute('required')
      }

      const event = new Event('change')

      input.dispatchEvent(event)
    })
  }

  init() {
    if (!this.existingAddressOption) {
      console.warn(
        'existing address option not found',
        '[data-existing-address-option]'
      )

      return
    }

    if (!this.newAddressOption) {
      console.warn('new address option not found', '[data-new-address-option]')

      return
    }

    this.render()

    this.checkbox.addEventListener('change', () => {
      this.render()
    })

    this.existingAddressOption.addEventListener('change', () => {
      this.render()
    })

    this.newAddressOption.addEventListener('change', () => {
      this.render()
    })
  }
}
