export const lazyLoading = () => {
  const images = document.querySelectorAll('[data-image-lazy]')
  const observer = new IntersectionObserver(
    function(entries) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting || entry.boundingClientRect.top < 0) {
          const img = entry.target
          img.setAttribute('src', img.dataset.src)
          img.setAttribute('srcset', img.dataset.srcSet)
          observer.unobserve(img)
        }
      })
    },
    { rootMargin: '0px 0px 1000px' }
  )
  for (let i = 0; i < images.length; i++) {
    observer.observe(images[i])
  }
}
