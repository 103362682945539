// assets
import './scrollToTop.scss'

export function scrollToTop() {
  const scrollButton = document.querySelector('[data-role="scroll-to-top"]')
  const content = document.querySelector('[data-role="content"]')

  if (scrollButton) {
    scrollButton.addEventListener('click', () => {
      content.scrollIntoView({ behavior: 'smooth' })
    })
  }
}
