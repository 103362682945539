export const staticFooter = () => {
  const mainContent = document.querySelector('[data-main-content]')
  const footer = document.querySelector('[data-footer]')
  const makeSpaceForFooter = () => {
    mainContent.style.marginBottom = `${footer.clientHeight}px`
  }
  if (footer && mainContent) {
    makeSpaceForFooter()
    window.addEventListener('resize', makeSpaceForFooter)
  }
}
