import { SELECTORS } from '../../lib/selectors'
import './beneficiaryForm.scss'
import { BeneficiaryAddressForm } from '@/components/beneficiaryForm/BeneficiaryAddressForm'

export const initBeneficiaryForm = page => {
  const beneficiaryForms = page.querySelectorAll('[data-beneficiary-form]')
  const beneficiaryLink = page.querySelector('[data-beneficiary-next]')
  const beneficiarySubmit = page.querySelector('[data-beneficiary-submit]')
  const beneficiaryCheckboxes = page.querySelectorAll(
    '[data-beneficiary-checkbox]'
  )
  const requiredElements = Array.from(beneficiaryForms).map(el => {
    return el.querySelectorAll(
      '[required]:not([data-beneficiary-address-form] [required])'
    )
  })

  requiredElements.forEach(element =>
    element.forEach(input => {
      input.removeAttribute('required')
    })
  )

  const allElementsExist = [
    beneficiaryCheckboxes.length > 0,
    beneficiaryCheckboxes.length === beneficiaryForms.length,
    beneficiaryLink,
    beneficiarySubmit,
  ].every(Boolean)

  if (!allElementsExist) {
    return
  }

  beneficiaryCheckboxes.forEach((checkbox, i) =>
    checkbox.addEventListener('change', () => {
      beneficiaryForms[i].classList.toggle(SELECTORS.active, checkbox.checked)

      requiredElements[i].forEach(el => {
        if (checkbox.checked) {
          el.setAttribute('required', '')
        } else {
          el.removeAttribute('required')
        }
      })

      beneficiarySubmit.classList.toggle(
        SELECTORS.active,
        Array.from(beneficiaryCheckboxes).some(el => el.checked)
      )

      beneficiaryLink.classList.toggle(
        SELECTORS.active,
        Array.from(beneficiaryCheckboxes).every(el => !el.checked)
      )
    })
  )
}

export const initBeneficiaryAddressForm = page => {
  const beneficiaryAddressForms = page.querySelectorAll(
    '[data-beneficiary-address-form]'
  )
  const beneficiaryAddressCheckboxes = page.querySelectorAll(
    '[data-beneficiary-address-checkbox]'
  )

  const allElementsExist = [
    beneficiaryAddressCheckboxes.length > 0,
    beneficiaryAddressCheckboxes.length === beneficiaryAddressForms.length,
  ].every(Boolean)

  if (!allElementsExist) {
    return
  }

  beneficiaryAddressForms.forEach(
    (form, i) =>
      new BeneficiaryAddressForm(form, beneficiaryAddressCheckboxes[i])
  )
}
